/*---Custom Modal start ---  */
.modal-wrapper {
    @extend .padding-16;

    .modal-header {
        .modal-text-header {
            @extend .f-20;
            @extend .fw-600;
        }
    }
}

.modal-header {
    .modal-header-title {
        @extend .padding-bottom-10;
    }

    .modal-description {
        @extend .padding-top-16;
    }
}

.modal {
    .MuiDialog-container {
        .MuiPaper-root {
            @extend .max-width-1500;
        }
    }
    .MuiDialog-paper {
        @extend .width-1200;
        @extend .max-width-1500;
        @extend .height-680;
        @extend .max-height-800;
    }
}

.custom-modal {
    .MuiDialog-paperWidthSm {
        @extend .width-480;
        @extend .max-width-1000;
        @extend .height-600;
        @extend .max-height-800;
    }
}

.modal-space-between {
    @include flex-space-between;
}

.space-between-footer {
    display: flex;
    justify-content: space-between;
    margin: 0 10px 0 10px;
}

.flex-end-footer {
    display: flex;
    justify-content: flex-end;
    margin: 0 10px 0 10px;
}

/*---Custom Modal end ---  */
